import dynamic from "next/dynamic";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, RefObject, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { SignupInputFieldName, UserStatus } from "@finbackoffice/enums";
import {
    formatAppNameText,
    HeaderLayoutEnum,
    ISiteConfigLayouts,
    ProfileLayoutSectionEnum,
    WalletLayoutEnum,
} from "@finbackoffice/fe-core";
import {
    AuthContext,
    ChatContext,
    ConfigContext,
    CrmContext,
    ModalsContext,
    SiteNotificationsContext,
    UserAccountContext,
    useRuntimeConfig,
    useSiteConfig,
    useTranslation,
    useLayoutProfileConfig,
} from "@finbackoffice/site-core";
import classnames from "classnames";
import Verification from "components/account/verification/Verification";
import Button from "components/base/button/Button";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Loading from "components/base/loading/Loading";
import Modal, { IModalForwardRefProps } from "components/base/modal/Modal";
import { Svg } from "components/base/svg/Svg";
import Translate from "components/base/translate/Translate";
import ExchangeRatePanel from "components/header/exchange-rate/ExchangeRatePanel";
import { NotificationContext } from "contexts";
import { roboto } from "fonts/roboto";
import { useSignupConfig } from "hooks";
import { ModalTypes, RouterQuery } from "utils/constants";
import WalletSelector from "./WalletSelector";
import styles from "./user-panel.module.sass";

const WalletWidgetAsync = dynamic<{
    onClose: () => void;
}>(() => import("./wowkorea-wallet/WowkoreaWallet").then((module) => module.WalletWidget), {
    loading: () => <Loading wrapperClassName={styles.walletModalLoader} />,
    ssr: false,
});

const PixWalletWidget = dynamic(
    () => import("./pix-wallet/PixWalletWidget").then((module) => module.PixWalletWidget),
    {
        loading: () => <Loading wrapperClassName={styles.walletModalLoader} />,
        ssr: false,
    },
);

const TolaWalletWidget = dynamic(
    () => import("./tola-wallet/TolaWalletWidget").then((module) => module.TolaWalletWidget),
    {
        loading: () => <Loading wrapperClassName={styles.walletModalLoader} />,
        ssr: false,
    },
);

const Account = dynamic(() => import("components/account/Account"), {
    loading: () => <Loading wrapperClassName={styles.accountModalLoader} />,
    ssr: false,
});

const UserPanel: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const showExchangeRatePanel = useSiteConfig<boolean>("showExchangeRatePanel");
    const { accountModalRef, walletModalRef } = useContext(ModalsContext);
    const { unreadMessages, chatStats } = useContext(ChatContext);
    const { unreadMessagesCount } = useContext(CrmContext);
    const { loadNotifications } = useContext(SiteNotificationsContext);
    const {
        logout,
        userEmail,
        username,
        userProfileStatus,
        loadProfile,
        isKycVerified,
        isEmailVerified,
        isPhoneVerified,
    } = useContext(UserAccountContext);
    const { setCurrentModal } = useContext(ModalsContext);
    const { userToken } = useContext(AuthContext);
    const router = useRouter();
    const { config: referralConfig } = useLayoutProfileConfig(ProfileLayoutSectionEnum.Referral);

    const verificationModalRef: RefObject<IModalForwardRefProps> = useRef(null);

    const { siteConfigs, loadSiteConfig, siteRegistrationConfig } = useContext(ConfigContext);
    const { signupConfigObj } = useSignupConfig([SignupInputFieldName.Username]);
    const { notice } = useContext(NotificationContext);
    const { t } = useTranslation();

    const onExitClickHandler = async () => {
        logout();
    };

    const onWalletClose = useCallback(async () => {
        await loadProfile();

        setCurrentModal(null);
    }, [loadProfile, setCurrentModal]);

    const openAccountModal = useCallback(async () => {
        await loadProfile();
        accountModalRef.current?.open();
    }, [accountModalRef, loadProfile]);

    const onReferralClick = useCallback(() => {
        router.push({
            pathname: router.pathname,
            query: {
                ...router.query,
                type: RouterQuery.Account,
                directory: RouterQuery.Referral,
            },
        });
        accountModalRef.current?.open();
    }, [accountModalRef, router]);

    useEffect(() => {
        if (userProfileStatus) {
            if (router.query?.type === RouterQuery.Account) {
                accountModalRef.current?.open();
            } else if (
                (siteRegistrationConfig?.require_email_verification && !isEmailVerified) ||
                (siteRegistrationConfig?.require_phone_verification && !isPhoneVerified)
            ) {
                verificationModalRef.current?.open();
            } else if (siteRegistrationConfig?.require_id_verification && !isKycVerified) {
                notice(
                    {
                        type: "error",
                        title: t("verification_text"),
                        message: t("verification_idDescription"),
                    },
                    "modal",
                );
            }

            loadNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileStatus]);

    const unreadMessagesCountMemo = useMemo(() => {
        if (router.query.subdirectory?.includes(`${RouterQuery.Chat}_`)) {
            const chatId = (router.query.subdirectory as string).split("_")[2];

            const filteredCount = unreadMessages.filter(
                (msg) => msg.chat.id.toString() !== chatId,
            ).length;

            return filteredCount;
        }
        return unreadMessages.length;
    }, [router.query.subdirectory, unreadMessages]);

    const onDepositButtonClick = useCallback(() => {
        loadSiteConfig();
        walletModalRef.current?.open();
    }, [loadSiteConfig, walletModalRef]);

    const renderWallet = useMemo(() => {
        switch (COMMON_SITE_CONFIGS.wallet.type) {
            case WalletLayoutEnum.Pix:
                return (
                    <ErrorBoundary name={PixWalletWidget.name}>
                        <Modal
                            ref={walletModalRef}
                            styleClass={classnames(styles.walletPixModal, roboto.variable)}
                            type={ModalTypes.WALLET}
                            maskClosable={false}>
                            <PixWalletWidget walletModalRef={walletModalRef} />
                        </Modal>
                    </ErrorBoundary>
                );
            case WalletLayoutEnum.Tola:
                return (
                    <ErrorBoundary name={TolaWalletWidget.name}>
                        <Modal
                            ref={walletModalRef}
                            styleClass={classnames(styles.walletTolaModal, roboto.variable)}
                            type={ModalTypes.WALLET}
                            maskClosable={false}>
                            <TolaWalletWidget walletModalRef={walletModalRef} />
                        </Modal>
                    </ErrorBoundary>
                );
            default:
                return (
                    <ErrorBoundary name={WalletWidgetAsync.name}>
                        <Modal
                            ref={walletModalRef}
                            styleClass={classnames(styles.walletModal, roboto.variable)}
                            type={ModalTypes.WALLET}
                            closable={false}
                            onClose={onWalletClose}
                            maskClosable={false}>
                            <WalletWidgetAsync onClose={onWalletClose} />
                        </Modal>
                    </ErrorBoundary>
                );
        }
    }, [COMMON_SITE_CONFIGS.wallet.type, onWalletClose, walletModalRef]);

    if (!userToken) return <Loading wrapperClassName={styles.userPanelLoader} />;

    return (
        <>
            <FadeInAnimation>
                <div className={styles.loggedIn} data-testid="user-panel-header">
                    <nav>
                        <Link
                            href={{
                                pathname: router.pathname,
                                query: {
                                    ...router.query,
                                    type: RouterQuery.Account,
                                    directory: RouterQuery.Details,
                                    subdirectory: RouterQuery.Personal,
                                },
                            }}
                            shallow
                            className={styles.username}
                            data-testid="open-user-panel-username-button"
                            onClick={openAccountModal}>
                            <Svg
                                src={`/${formatAppNameText(
                                    COMMON_SITE_CONFIGS.appName,
                                )}/desktop/icons/header-login-icon.svg`}
                                wrapper="span"
                                className={styles.userIcon}
                            />
                            {userProfileStatus && userProfileStatus !== UserStatus.Registered && (
                                <Svg
                                    src="/common/desktop/base-icons/question-icon.svg"
                                    wrapper="span"
                                    className={styles.verificationReminder}
                                />
                            )}
                            {signupConfigObj.username ? username : userEmail}
                        </Link>
                        <WalletSelector />
                        <div className="user-panel-links">
                            {showExchangeRatePanel &&
                                siteLayoutsConfig.header === HeaderLayoutEnum.V2 && (
                                    <ErrorBoundary name={ExchangeRatePanel.name}>
                                        <ExchangeRatePanel />
                                    </ErrorBoundary>
                                )}
                            <Button
                                type="button"
                                onClick={onDepositButtonClick}
                                data-testid="open-wallet-button"
                                variant="primary"
                                className={styles.depositBtn}>
                                <Translate tid="userPanel_deposit" />
                                <Svg
                                    src="/common/desktop/base-icons/wallet.svg"
                                    wrapper="span"
                                    className={styles.depositIcon}
                                />
                            </Button>

                            <Link
                                href={{
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        type: RouterQuery.Account,
                                        directory: RouterQuery.Bets,
                                        subdirectory: RouterQuery.Sport,
                                    },
                                }}
                                shallow>
                                <Svg
                                    src="/common/desktop/base-icons/account-my-bets.svg"
                                    wrapper="span"
                                    className={styles.myBetsBtn}
                                    onClick={() => accountModalRef.current?.open()}
                                    data-testid="open-user-panel-bet-history-button"
                                />
                            </Link>
                            {!!referralConfig && (
                                <Button
                                    className={styles.referralBtn}
                                    type="button"
                                    // disabled={!userReferralSlug}
                                    onClick={onReferralClick}>
                                    <Svg
                                        src="/common/desktop/base-icons/account-referral.svg"
                                        wrapper="span"
                                        className="svg-icon"
                                    />
                                </Button>
                            )}

                            <Link
                                href={{
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        type: RouterQuery.Account,
                                        directory: RouterQuery.Messages,
                                        subdirectory: RouterQuery.Inbox,
                                    },
                                }}
                                shallow>
                                <span
                                    className={styles.messages}
                                    onClick={() => accountModalRef.current?.open()}>
                                    <Svg
                                        src="/common/desktop/base-icons/account-messages.svg"
                                        wrapper="span"
                                        className={styles.messagesBtn}
                                    />
                                    {(Boolean(unreadMessagesCountMemo) ||
                                        !!chatStats?.unread_message) && (
                                        <i className={styles.msgCount}>
                                            {unreadMessagesCountMemo +
                                                (chatStats?.unread_message ?? 0)}
                                        </i>
                                    )}
                                </span>
                            </Link>
                            {COMMON_SITE_CONFIGS.crm.enable && (
                                <Button
                                    className={styles.referralBtn}
                                    type="button"
                                    onClick={() => window._smartico.dp("dp:inbox")}>
                                    <Svg
                                        src="/common/desktop/base-icons/notifications.svg"
                                        wrapper="span"
                                        className="svg-icon"
                                    />
                                    {unreadMessagesCount > 0 && (
                                        <i className={styles.msgCount}>{unreadMessagesCount}</i>
                                    )}
                                </Button>
                            )}
                        </div>
                    </nav>
                    <Button
                        type="button"
                        onClick={onExitClickHandler}
                        variant="primary"
                        className={styles.exitBtn}
                        data-testid="exit-button">
                        <Translate tid="userPanel_exit" />
                    </Button>
                </div>
            </FadeInAnimation>
            <Modal ref={accountModalRef} type={ModalTypes.ACCOUNT} styleClass={styles.accountModal}>
                <Account />
            </Modal>
            <Modal
                ref={verificationModalRef}
                styleClass={styles.verifyModal}
                closable={userProfileStatus !== UserStatus.Registered}
                type={ModalTypes.VERIFICATION}>
                <ErrorBoundary name={Verification.name}>
                    <Verification />
                </ErrorBoundary>
            </Modal>
            {siteConfigs && renderWallet}
        </>
    );
};
export default UserPanel;
